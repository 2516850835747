.pagination-wrap {
  @apply bg-white flex justify-between items-center gap-2 flex-wrap;

  .pagination-content {
    @apply flex items-center gap-2;
  }
}

.pagination-container {
  @apply relative flex items-center;
}

.rc-pagination {
  @apply flex items-center w-fit h-full text-xs;

  :global {
    .rc-pagination-simple li {
      @apply h-full #{!important};
    }

    .rc-pagination-item-active {
      @apply font-bold;
    }

    .rc-pagination-prev {
      @apply outline-none;
      div {
        @apply border rounded bg-white;
      }

      svg path {
        fill: #66707b !important;
      }
    }

    .rc-pagination-prev.rc-pagination-disabled {
      @apply outline-none;
      div {
        @apply border rounded;
      }

      svg path {
        fill: #66707b !important;
      }
    }

    .rc-pagination-next {
      @apply outline-none;
      div {
        @apply border rounded bg-white;
      }

      svg path {
        fill: #66707b !important;
      }
    }

    .rc-pagination-next.rc-pagination-disabled {
      @apply outline-none;
      div {
        @apply border rounded;
      }

      svg path {
        fill: #66707b !important;
      }
    }

    .rc-pagination-item {
      @apply outline-none px-2
    }

    .rc-pagination-simple-pager {
      @apply flex items-center gap-x-1 #{!important};
      input {
        @apply w-8 h-8 px-0 text-center border rounded #{!important};
      }
    }

    .rc-pagination-jump-prev {
      @apply outline-none;
    }

    .rc-pagination-jump-next {
      @apply outline-none px-1;
    }
  }
}

.rc-pagination-disabled {
  svg {
    @apply fill-current cursor-not-allowed;
  }

  div {
    @apply cursor-not-allowed;
  }
}

.page-size {
  @apply flex items-center rounded-full cursor-pointer border-2 px-3 py-1 space-x-2
  focus:outline-none text-xs font-semibold;
}

.page-size-open {
  @apply border-2 border-primary;
}

.page-size-options-container {
  @apply relative border rounded h-6;
  button {
    @apply py-0 bg-transparent border-0 px-3 h-6 text-xs font-normal;
  }
}

.page-size-options {
  @apply text-xs absolute bg-white w-24 z-20 focus:outline-none shadow-md rounded left-1/2 transform mt-1 -translate-x-1/2;
}

.page-size-option {
  @apply px-4 py-1 cursor-pointer hover:text-primary;
}

.page-size-option-active {
  @apply bg-none text-primary;
}

.delete-multiple-wrap {
  @apply flex justify-end gap-4 items-center md:ml-auto;
  &.empty {
    .btn-delete-multiple {
      @apply cursor-default;
    }
  }

  .btn-delete-multiple {
    @apply flex justify-start px-2 py-1 rounded-md h-auto;
  }

  .disable {
    @apply cursor-not-allowed;
  }

  .enable {
    @apply bg-white text-primary cursor-pointer;
  }
}

.paging-total {
  @apply font-semibold;
}
